import { useEffect, useState } from "react";
import Latpaycomponent from "@latpay/latpay-react-component";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
const Client = () => {
  const [formData, setFormData] = useState({
    Merchant_User_Id: "", //provided by latpay
    PublicKey: "", //provided by latpay
    TransKey: "", //sha256(amount+currency+reference+is3dcheck+datakey)
    AuthStatusCheck: "", //sha256(amount+currency+reference+datakey)
    DataKey: "", //provided by latpay
    Amount: "", //transaction amount
    Currency: "", //transaction currency
    Description: "", //transaction description
    Reference: "", //transaction reference
    Email: "", //customer email
    Firstname: "", //customer first name
    Lastname: "", //customer last name
  });

  const [result, setResult] = useState("");
  const [authStatusCheck, setAuthStatusCheck] = useState("");
  const [showPayment, setShowPayment] = useState(false);
  useEffect(() => {
    Latpaycomponent()
      .then(() => {
        const latpayElement = document.getElementById("latpay-element");
        if (latpayElement) {
          latpayElement.innerHTML = ""; // Clear the content
        }

        if (window.LatpayCheckout) {
          window.LatpayCheckout.open({
            merchantuserid: formData.Merchant_User_Id, // issued by latpay
            publickey: formData.PublicKey, //issued by latpay
            currency: formData.Currency, // transaction currency
            amount: formData.Amount, //transaction amount
            reference: formData.Reference, // transaction reference
            description: formData.Description, // transaction description
            status: (status) => {
              // status...
            },
          });
          window.LatpayCheckout.OnPaymentCompleted = (val) => {
            //callback response
            setResult(val);
            //auth status check request construction
            if (val.responsekey != "") {
              const authRequest = {
                merchantid: formData.Merchant_User_Id,
                amount: formData.Amount,
                currency: formData.Currency,
                reference: formData.Reference,
                transactionkey: formData.AuthStatusCheck,
              };

              fetch(
                "https://lateralpayments.com/checkout/authorise/authstatuscheck",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json; charset=utf-8",
                  },
                  body: JSON.stringify(authRequest),
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  //authstatus check response
                  setAuthStatusCheck(data);
                })
                .catch((err) => {
                  console.error("Error in Auth Status Check:", err);
                });
            }
          };
        } else {
          console.error("LatpayCheckout is not available");
        }

        /*only for card*/
        // latpayCheckout.open_card({
        //   merchantuserid: formData.Merchant_User_Id,// issued by latpay
        //   publickey: formData.PublicKey,//issued by latpay
        //   currency: formData.Currency,// transaction currency
        //   amount: formData.Amount,//transaction amount
        //   reference: formData.Reference,// transaction reference
        //   description: formData.Description,// transaction description
        //   status: (status) => {
        //    //status.....
        //   },
        // });

        /*only for gpay*/
        // latpayCheckout.open_googlepay({
        //   merchantuserid: formData.Merchant_User_Id,
        //   publickey: formData.PublicKey,
        //   currency: formData.Currency,
        //   amount: formData.Amount,
        //   reference: formData.Reference,
        //   description: formData.Description,
        //   status: (status) => {
        //     //status.....
        //    },
        // });

        /* only for applepay */
        // latpayCheckout.open_applepay({
        //   merchantuserid: formData.Merchant_User_Id,
        //   publickey: formData.PublicKey,
        //   currency: formData.Currency,
        //   amount: formData.Amount,
        //   reference: formData.Reference,
        //   description: formData.Description,
        //   status: (status) => {
        //     //status.....
        //    },
        // });
      })
      .catch((error) => {
        console.error("Error loading scripts:", error);
      });
  }, [showPayment]); // Ensure formData is included in the dependency array if it changes

  const handleCheckout = (e) => {
    e.preventDefault(); // Prevent default button action if necessary

    if (window.LatpayCheckout) {
      window.LatpayCheckout.secure3DPayment({
        amount: formData.Amount,
        currency: formData.Currency,
        reference: formData.Reference,
        description: formData.Description,
        firstname: formData.Firstname,
        lastname: formData.Lastname,
        email: formData.Email,
        datakey: formData.DataKey,
        transkey: formData.TransKey,
        is3dcheck: "N",
      });
    } else {
      console.error("LatpayCheckout is not available");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  console.log(formData)
  const customStyle = {
    container:{
      marginTop: "20px ",
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      padding: "20px",
    },
    button:{
      marginTop:'22px',
      display: "block",
      margin: "20px auto",
      padding: "10px 20px",
      backgroundColor: "#007bff",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "16px",
      transition: "background-color 0.3s",
    },
    input:{
      marginBottom:'8px'
    }
  };

  return (
    <div>
      <Container
        style={customStyle.container}
      >
        <h3 style={{ textAlign: "center" }}>Latpay React Component Test</h3>
        {/* Your component content here */}
        {showPayment ? (
          <Container>
            <div id="latpay-element"></div>
            <Button onClick={handleCheckout}>Click to Checkout</Button>
            {result != "" && authStatusCheck != "" ? (
              <div
                style={{
                  wordWrap: "break-word",
                  wordBreak: "break-word",
                  width: "60%",
                  marginTop: "12px",
                  marginBottom: "12px",
                }}
              >
                <div>
                  <p>Result :</p>
                  <p>{JSON.stringify(result)}</p>
                </div>
                <div>
                  <p>Auth Status Check Result :</p>
                  <p>{JSON.stringify(authStatusCheck)}</p>
                </div>
              </div>
            ) : (
              ""
            )}
          </Container>
        ) : (
          <Container>
            <Row>
              <Col>
                <lable>Merchant_User_Id:</lable>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="Merchant_User_Id"
                  value={formData.Merchant_User_Id}
                  onChange={handleChange}
                  style={customStyle.input}
                />
                <lable>PublicKey: </lable>
                <Form.Control
                  type="text"
                  placeholder="PublicKey"
                  name="PublicKey"
                  value={formData.PublicKey}
                  onChange={handleChange}
                  style={customStyle.input}
                />
                <lable>TransKey: </lable>
                <Form.Control
                  type="text"
                  placeholder="TransKey"
                  name="TransKey"
                  value={formData.TransKey}
                  onChange={handleChange}
                  style={customStyle.input}
                />

                <lable>AuthStatusCheck: </lable>
                <Form.Control
                  type="text"
                  placeholder="AuthStatusCheck"
                  name="AuthStatusCheck"
                  value={formData.AuthStatusCheck}
                  onChange={handleChange}
                  style={customStyle.input}
                />

                <lable>DataKey: </lable>
                <Form.Control
                  type="text"
                  placeholder="DataKey"
                  name="DataKey"
                  value={formData.DataKey}
                  onChange={handleChange}
                  style={customStyle.input}
                />

                <lable>Amount: </lable>
                <Form.Control
                  type="text"
                  placeholder="Amount"
                  name="Amount"
                  value={formData.Amount}
                  onChange={handleChange}
                  style={customStyle.input}
                />
              </Col>
              <Col>
                <lable>Currency: </lable>
                <Form.Control
                  type="text"
                  placeholder="Currency"
                  name="Currency"
                  value={formData.Currency}
                  onChange={handleChange}
                  style={customStyle.input}
                />

                <lable>Description: </lable>
                <Form.Control
                  type="text"
                  placeholder="Description"
                  name="Description"
                  value={formData.Description}
                  onChange={handleChange}
                  style={customStyle.input}
                />

                <lable>Reference: </lable>
                <Form.Control
                  type="text"
                  placeholder="Reference"
                  name="Reference"
                  value={formData.Reference}
                  onChange={handleChange}
                  style={customStyle.input}
                />

                <lable>Email: </lable>
                <Form.Control
                  type="text"
                  placeholder="Email"
                  name="Email"
                  value={formData.Email}
                  onChange={handleChange}
                  style={customStyle.input}
                />

                <lable>Firstname: </lable>
                <Form.Control
                  type="text"
                  placeholder="Firstname"
                  name="Firstname"
                  value={formData.Firstname}
                  onChange={handleChange}
                  style={customStyle.input}
                />

                <lable>Lastname: </lable>
                <Form.Control
                  type="text"
                  placeholder="Lastname"
                  name="Lastname"
                  value={formData.Firstname}
                  onChange={handleChange}
                  style={customStyle.input}
                />
              </Col>
            </Row>
            <Button
              style={customStyle.button}
              onClick={() => {
                setShowPayment(!showPayment);
              }}
            >
              Checkout
            </Button>
          </Container>
        )}
      </Container>
    </div>
  );
};

export default Client;
