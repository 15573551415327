import logo from './logo.svg';
import './App.css';
import Client from './Client';
// index.js or App.js
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
    <Client/>
    </div>
  );
}

export default App;
